.shop-button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 60px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    transition: background-color 200ms ease;
    padding: 10px 13px;

    &__icon {
        fill: #fff;
        margin-right: 10px;
    }

    &__count {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 18px;
        font-size: 11px;
        color: $color-general;
        border-radius: 4px;
        border: 1px solid #fff;
        background-color: #fff;
        margin-left: -16px;
        transform: scale(0);
        transition: color 100ms ease, margin-left 200ms ease 100ms, transform 200ms ease 100ms;

        &.filled {
            margin-left: 12px;
            transform: scale(1);
            transition: color 100ms ease 200ms, margin-left 200ms ease, transform 200ms ease;
        }
    }

    &:hover {
        color: #fff;
        background-color: rgba(#fff, 0.2);
    }
}