.notify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.14);
    background-color: #fff;
    padding: 35px 40px;
    margin-bottom: 30px;

    &-body {
        display: flex;
        align-items: center;
    }

    &-text {
        // Стили текстовой обертки
    }

    &-icon {
        flex: 0 0 auto;
        width: 59px;
        height: 52px;
        fill: #c0c0c0;
        margin-right: 30px;
    }

    &-title {
        font-size: 24px;
        font-weight: 700;
        color: $color-text;
        line-height: 1.5;
    }

    &-desc {
        font-size: 16px;
        font-weight: 400;
        color: $color-caption;
        line-height: 1.5;
    }

    &-button {
        // Дополнительные стили bootstrap кнопке
    }
}

@media(max-width: 767px) {
    .notify {
        flex-direction: column;
        align-items: flex-start;

        &-body {
            margin-bottom: 15px;
        }

        &-icon {
            margin-right: 15px;
        }
    }
}

@media(max-width: 575px) {
    .notify {
        padding: 15px 20px;

        &-body {
            flex-direction: column;
            align-items: flex-start;
        }

        &-icon {
            margin-right: 15px;
        }
    }
}
