.header-contacts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;

    &-item {
        display: inline-flex;
        align-items: center;
        margin-left: 42px;

        &__icon {
            fill: $color-general;
            margin-right: 6px;
        }

        &__text {
            font-size: 16px;
            line-height: 25px;
            color: $color-text;

            &_email {
                text-decoration: underline;
            }

            &_phone {
                font-weight: 600;
            }
        }
    }
}
