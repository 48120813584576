.back-to-catalog{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 174px;
	padding: 8px 0;
	margin: 15px 0;
	border-radius: 4px;
	border: 1px solid #ccc;
	background-color: #fff;
	color: #373a3c;
	font-size: 12px;
	line-height: 13px;
	transition: .3s;

	&:hover{
		color: #fff;
		background-color: #6c757d;
		border-color: #6c757d;
		> svg{
			fill: #fff;
		}
	}
	> svg{
		margin: 0 6px 2px 0;
		fill: #373a3c;
		transition: .3s;
	}
}