.index-section {
    position: relative;
}

.sections-group {
    background-color: #FFFFFF;
    padding: 15px 0 25px;

    &_inverse {
        background-color: #F5F5F5
    }
}
