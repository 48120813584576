.mobile-filters-trigger{
	display: block;
	height: 40px;
	background-color: $color-general;
	border: 1px solid $color-general;
	color: #fff !important;
	font-size: 16px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
	margin-bottom: 15px;
	cursor: pointer;
	transition: .2s;
	&:hover{
		background-color: #fff;
		color: $color-general !important;
	}
}