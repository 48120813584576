.product-amount {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 85px;
    height: 30px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 100%;
        color: #9b9b9b;
        font-size: 7px;
        font-weight: 700;
        -webkit-transition: .2s;
        transition: .2s;
        border-radius: 30px;
        cursor: pointer;
        user-select: none;

        &:hover {
            color: $color-general;
        }
    }

    &__field {
        display: flex;
        width: 80px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;

        input {
            border: none;
            width: 100%;
            text-align: center;
            color: #373a3c;
            font-size: 15px;
            line-height: 21px;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
