.form {
    border: 1px solid #ddd;
    background-color: #fff;
    max-width: 670px;
    max-width: 100%;
    padding: 20px 30px 35px;

    &-title {
        display: block;
        font-weight: 700;
        font-size: 20px;
        color: $color-text;
        border-bottom: 1px solid #d8d8d8;
        padding: 0 0 10px;
        margin: 0 0 16px;
    }

    &-group {
        &__title {
            margin-bottom: 5px;
        }
    }
}


.errors-wrapper {
    .is-invalid + .invalid-feedback {
        display: block;
    }
}