.breadcrumbs {
	padding: 28px 0 20px;
	ul {
		display: block;
		font-size: 0;
		li {
			display: inline;
			font-size: 16px;
			font-weight: 400;
			color: #818a91;
			a {
				color: $color-general;
				&:hover{
					text-decoration: underline;
				}
			}
			&:not(:last-child){
				&:after{
					content: '/';
					margin: 0 10px 0 5px;
					color: #818a91;
					font-size: 16px;
					font-weight: 400;
				}
			}
		}
	}
}

@media(max-width: 767px) {
	.breadcrumbs{
		padding: 14px 0 8px;
		ul{
			li:after{
				margin: 0 3px;
			}
		}
	}
}