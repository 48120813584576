.product{
	&__top-info {
		padding: 0 0 44px;
	}
}


@media (max-width: 575px) {
	.product{
		&__top-info {
			padding: 0 0 15px;
		}
	}
}