.header-logo {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 80px;

    &__image {
        max-width: 100%;
        max-height: 100%;
    }
}