.extra-products-slider{
	padding: 30px 0;
	&.active &{
		&__slider-header{
			.nav-buttons{
				display: flex;
			}
		}
	}
	&__slider-header{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		&-title{
			display: block;
			color: #373a3c;
			font-size: 24px;
			font-weight: 600;
		}
		.nav-buttons{
			display: none;
			justify-content: space-between;
			width: 75px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				height: 35px;
				border-radius: 4px;
				border: 1px solid #e5e5e5;
				background-color: #fff;
				transition: .3s;
				&:hover{
					border-color: $color-general;
					> svg{
						fill: $color-general;
					}
				}
				&.slick-hidden{
					display: none;
				}
				&.next{
					> svg{
						transform: rotate(180deg);
					}
				}
				> svg{
					fill: #9b9b9b;
					transition: .3s;
				}
			}
		}
	}
	&__slider-body{
		overflow: hidden;
		.slick-list{
			margin: 0 -15px;
			overflow-y: visible;
			overflow-x: hidden;
			.slick-track{
				min-width: 100%;
			}
		}
		.slick-slide{
			margin: 0 15px;
		}
	}
	&__no-slider{
		padding: 15px 0;
	}
}


@media(max-width: 575px){
	.extra-products-slider{
		padding: 15px 0;
		&__slider-header{
			flex-direction: column;
			align-items: flex-start;
			&-title{
				font-size: 22px;
				padding-bottom: 5px;
			}
		}
	}
}