.footer {
    flex: 0 1 auto;
	background-color: #f5f5f5;
	&-block{
		padding: 30px 0;
		&__title{
			display: block;
			padding: 0 0 3px;
			color: $color-general;
			font-size: 18px;
			font-weight: 600;
		}
		&__menu{
			li{
				a{
					padding: 5px 0;
				}
			}
		}
		&__req{
			display: block;
			padding: 5px 0;
			&_mail{
				text-decoration: underline;
			}
		}
		a{
			transition: .2s;
			&:hover{
				color: $color-general;
			}
		}
		&__menu li a, 
		&__req span, 
		&__req a{
			display: block;
			color: #373a3c;
			font-size: 15px;
			font-weight: 400;
			line-height: 22px;
		}
	}
	&-callback{
		margin: 30px 0 0;
	}
	&__lower-part{
		margin-top: 23px;
		border-top: 1px solid #e1e1e1;
		&-wrapper{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			height: 58px;
		}
		&-left{
			display: flex;
			align-items: center;
			p{
				padding-right: 90px;
			}
			a{
				text-decoration: underline;
				transition: .3s;
				&:hover{
					color: $color-general;
					text-decoration: none;
				}
			}
			p, a{
				display: block;
				color: #999;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
	&__made-by{
		display: flex;
		align-items: center;
		color: #999;
		font-size: 14px;
		font-weight: 400;
		transition: .3s;
		> svg{
			fill: #9b9b9b;
			margin-left: 5px;
			transition: .3s;
		}
		&:hover{
			color: $color-general;
			> svg{
				fill: $color-general;
			}
		}
	}
}






@media (max-width : 991px) {
	.footer{
		&__lower-part{
			&-wrapper{
				height: auto;
				flex-direction: column;
				justify-content: center;
				padding: 10px 0;
			}
			&-left{
				flex-direction: column;
				p, a{
					padding: 4px 0;
					text-align: center;
				}
			}
		}
		
		&__made-by{
			padding: 4px 0;
			justify-content: center;
		}
	}
}

@media (max-width : 767px) {
	.footer{
		&-block{
			padding: 15px 0 8px;
		}
	}
}