.catalog-button {
    $this: &;
    position: relative;
    display: inline-flex;
    height: 60px;

    &-link {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 10px;
        transition: background-color 200ms ease;
    }

    &-lines {
        display: inline-block;
		position: relative;
		bottom: 1px;
		height: 2px;
		width: 16px;
        background-color: #fff;
        margin-right: 12px;
        transform: translateY(1px);
		&:after, &:before{
			content:"";
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #fff;
		}
		&:before{
			top: -6px;
		}
		&:after{
			bottom: -6px;
		}
    }

    &-title {
        display: inline-block;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #fff;
    }

    &-arrow {
        display: inline-block;
        border: 4px solid transparent;
        border-top: 5px solid #fff;
        margin-left: 12px;
        transform: translateY(3px);
    }

    .catalog-menu {
        display: none;
    }

    &:hover & {
        &-link {
            background-color: rgba(#fff, 0.2);
        }
    }

    &:hover {
        .catalog-menu {
            display: block;
        }
    }
}