.call-me {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 10;

    width: 50px;
    height: 200px;
    padding: 15px 0 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    border-radius: 10px 0 0 10px;

    cursor: pointer;
    color: #fff;
    background-color: #FF0000;

    transform: translateX(-50%);

    &:hover {
        .call-me__text {
            color: #fff;
        }
    }

    &__text {
        color: #fff;
        white-space: nowrap;
        font-weight: 600;
        
        transform: rotate(270deg);
    }

    &__svg {
        width: 20px;
        height: 20px;

        margin-bottom: 66px;


        fill: #fff;
    }
}

