* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    height: 100%;
}

body {
    min-width: 320px;
    height: 100%;
    margin: 0;
    padding: 0;
	font-family: sans-serif;
	&.opened {
		// position: fixed;
		// left: 0;
		// right: 0;
		// top: 0;
		overflow: hidden;
	}
}

::-webkit-input-placeholder { color: $color-placeholder; }
::-moz-placeholder          { color: $color-placeholder; }
:-moz-placeholder           { color: $color-placeholder; }
:-ms-input-placeholder      { color: $color-placeholder; }

ol, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
    line-height: normal;
    margin: 0;
    padding: 0;
}

a {
    color: $color-text;
    text-decoration: none;
    &:hover {
        color: $color-general;
        text-decoration: none;
    }
}

a:focus, button:focus, input:focus, textarea:focus {
    outline: 0;
    text-decoration: none;
}

// Базовая обертка сайта
.base {
    &-wrapper, &-container {
        width: 100%;
    }
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-container {
        flex: 1  auto;
        overflow: initial;
        padding: {
            top: 180px;
            bottom: 25px;
        }
    }

    &-page {
        padding-bottom: 90px;
    }
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.navbar {
    padding: 0;
}

.params-item~.params-delimeter {
    display: none;
}

.params-delimeter::before {
    content: '-';
}


//  
.mobile-search-trigger{
	svg{
		pointer-events: none;
	}
}
.nav-close{
	*{
		pointer-events: none;
	}
}



///  Bootstrap reset
.btn-primary{
	background-color: $color-general;
    border-color: $color-general;
}
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	background-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	border-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.page-link,
.page-link:hover{
	color: $color-bootstrap-dark;
}
.btn.disabled, .btn:disabled{
	opacity: 1;
}


// 8 колоночная сетка
.f{
    &-container{
        width: 100%;
        padding: 0px calcFluidSize(15, 60, 1199, 1920);
        margin: 0 auto;
    }
    &-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        margin: 0 -15px;
    }
    &-col{
        position: relative;
        width: 100%;
        min-height: 1px;
        padding: 0 15px;
    }
    &-col-1{
        flex: 0 0 calc(100% / 8);
        max-width: calc(100% / 8);
    }
    &-col-2{
        flex: 0 0 calc(100% / 4);
        max-width: calc(100% / 4);
    }
    &-col-3{
        flex: 0 0 calc(100% / 8 * 3);
        max-width: calc(100% / 8 * 3);
    }
    &-col-4{
        flex: 0 0 calc(100% / 2);
        max-width: calc(100% / 2);
    }
    &-col-5{
        flex: 0 0 calc(100% / 8 * 5);
        max-width: calc(100% / 8 * 5);
    }
    &-col-6{
        flex: 0 0 calc(100% / 8 * 6);
        max-width: calc(100% / 8 * 6);
    }
    &-col-7{
        flex: 0 0 calc(100% / 8 * 7);
        max-width: calc(100% / 8 * 7);
    }
    &-col-8{
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 1199px){
    .f{
        &-container{
            padding: 0px 15px;
        }
    }
}

@media (min-width: 576px) {
    .f{
        &-col-sm-1{
            flex: 0 0 calc(100% / 8);
            max-width: calc(100% / 8);
        }
        &-col-sm-2{
            flex: 0 0 calc(100% / 4);
            max-width: calc(100% / 4);
        }
        &-col-sm-3{
            flex: 0 0 calc(100% / 8 * 3);
            max-width: calc(100% / 8 * 3);
        }
        &-col-sm-4{
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
        }
        &-col-sm-5{
            flex: 0 0 calc(100% / 8 * 5);
            max-width: calc(100% / 8 * 5);
        }
        &-col-sm-6{
            flex: 0 0 calc(100% / 8 * 6);
            max-width: calc(100% / 8 * 6);
        }
        &-col-sm-7{
            flex: 0 0 calc(100% / 8 * 7);
            max-width: calc(100% / 8 * 7);
        }
        &-col-sm-8{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

@media (min-width: 768px) {
    .f{
        &-col-md-1{
            flex: 0 0 calc(100% / 8);
            max-width: calc(100% / 8);
        }
        &-col-md-2{
            flex: 0 0 calc(100% / 4);
            max-width: calc(100% / 4);
        }
        &-col-md-3{
            flex: 0 0 calc(100% / 8 * 3);
            max-width: calc(100% / 8 * 3);
        }
        &-col-md-4{
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
        }
        &-col-md-5{
            flex: 0 0 calc(100% / 8 * 5);
            max-width: calc(100% / 8 * 5);
        }
        &-col-md-6{
            flex: 0 0 calc(100% / 8 * 6);
            max-width: calc(100% / 8 * 6);
        }
        &-col-md-7{
            flex: 0 0 calc(100% / 8 * 7);
            max-width: calc(100% / 8 * 7);
        }
        &-col-md-8{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

@media (min-width: 992px) {
    .f{
        &-col-lg-1{
            flex: 0 0 calc(100% / 8);
            max-width: calc(100% / 8);
        }
        &-col-lg-2{
            flex: 0 0 calc(100% / 4);
            max-width: calc(100% / 4);
        }
        &-col-lg-3{
            flex: 0 0 calc(100% / 8 * 3);
            max-width: calc(100% / 8 * 3);
        }
        &-col-lg-4{
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
        }
        &-col-lg-5{
            flex: 0 0 calc(100% / 8 * 5);
            max-width: calc(100% / 8 * 5);
        }
        &-col-lg-6{
            flex: 0 0 calc(100% / 8 * 6);
            max-width: calc(100% / 8 * 6);
        }
        &-col-lg-7{
            flex: 0 0 calc(100% / 8 * 7);
            max-width: calc(100% / 8 * 7);
        }
        &-col-lg-8{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

@media (min-width: 1200px) {
    .f{
        &-col-xl-1{
            flex: 0 0 calc(100% / 8);
            max-width: calc(100% / 8);
        }
        &-col-xl-2{
            flex: 0 0 calc(100% / 4);
            max-width: calc(100% / 4);
        }
        &-col-xl-3{
            flex: 0 0 calc(100% / 8 * 3);
            max-width: calc(100% / 8 * 3);
        }
        &-col-xl-4{
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
        }
        &-col-xl-5{
            flex: 0 0 calc(100% / 8 * 5);
            max-width: calc(100% / 8 * 5);
        }
        &-col-xl-6{
            flex: 0 0 calc(100% / 8 * 6);
            max-width: calc(100% / 8 * 6);
        }
        &-col-xl-7{
            flex: 0 0 calc(100% / 8 * 7);
            max-width: calc(100% / 8 * 7);
        }
        &-col-xl-8{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}




@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (max-width: 1199px) {
    .container {
        width: 100%;
        max-width: 100%;
    }

    .base {
        &-container {
            padding: {
                top: 60px;
                bottom: 25px;
            }
        }
    }
}

