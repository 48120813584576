.index-row{
	padding: 38px 0 20px;
	&_products, &_news{
		background-color: #f5f5f5;
		.mobile_more{
			margin: 0 0 15px !important;
		}
	}
	&_stock{
		padding: 30px 0;
		background-color: #fff;
		.slick-list{
			margin: 0 -15px;
			.slick-track{
				.slick-slide{
					margin: 0 15px;
				}
			}
		}
	}
	&_categories{
		padding: 20px 0 44px;
		background-color: #fff;
	}
	&__header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 16px;
		&-title{	
			display: flex;
    		align-items: center;
			color: #373a3c;
			font-size: 30px;
			font-weight: 600;
			img{
				margin-right: 13px;
			}
		}
	}
	&__body{
		padding: 4px 0 20px;
	}
	.see_more, .mobile_more{
		display: inline-block;
		padding: 7px 10px;
		border-radius: 4px;
		border: 1px solid #ccc;
		color: #999;
		font-size: 12px;
		font-weight: 400;
		line-height: 13px;
		text-align: center;
		transition: .3s;
		&:hover{
			background-color: #ccc;
			color: #fff;
			> svg{
				fill: #fff;
			}
		}
		> svg{
			margin: 0 0 2px 7px;
			fill: #999;
			transform: rotate(180deg);
			transition: .3s;
		}
	}
	.mobile_more{
		margin: 15px 0 0;
	}
}

@media (max-width : 1199px) {
	.index-row{
		&_products{
			.index-row__body{
				>.row{
					> div{
						&:last-child{
							display: none;
						}
					}
				}
			}
		}
	}
}

@media (max-width : 991px) {
	.index-row{
		&_stock{
			.slick-list{
				margin: 0 -10px;
				.slick-track{
					.slick-slide{
						margin: 0 10px;
						height: auto;
						max-width: none;
					}
				}
			}
		}
		&_products{
			.index-row__body{
				>.row{
					> div{
						&:last-child{
							display: block;
						}
					}
				}
			}
		}
	}
}

@media (max-width : 767px) {
	.index-row{
		padding: 15px 0 30px;
		&__header{
			padding: 10px 0 0;
			&-title{
				font-size: 20px;
				img{
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
		}
		.see_more{
			display: none;
		}
		.mobile_more{
			width: 100%;
		}
		&_categories{
			padding-top: 30px;
		}
		&_products{
			.index-row__body{
				>.row{
					> div{
						&:nth-child(3){
							display: none;
						}
						&:last-child{
							display: none;
						}
					}
				}
			}
		}
		&__body{
			padding: 0;
		}
	}
}