.catalog-menu, .catalog-submenu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
}

.catalog-menu {
    z-index: 100;
    top: 100%;
    left: 0;
    width: 300px;

    &-item {
        $this: &;
        display: block;
        transition: background-color 100ms ease;

		&.all-cats &{
            background-color: $color-general;
            &__text{
                color: #fff;
            }
		}
        &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $color-text;
            transition: color 100ms ease;
        }

        &__arrow {
            width: 6px;
            height: 9px;
            fill: #999;
            transform: rotate(180deg);
            transition: fill 100ms ease;
        }

        &:hover {
            background-color: $color-general;

            #{$this}__text {
                color: #fff;
            }

            #{$this}__arrow {
                fill: #fff;
            }

            .catalog-submenu {
                display: block;
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 20px;
    }
}

.catalog-submenu {
    display: none;
    z-index: 105;
    top: 0;
    left: 100%;
    width: 870px;
    min-height: 100%;
    padding: 8px 0;

    &-link {
        display: block;
        max-width: 290px;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: $color-text;
        transition: color 100ms ease;
        padding: 8px 25px;

        &:hover {
            color: $color-general;
        }
    }
}
