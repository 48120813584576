.sidebar-helper{
	display: block;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 320px;
	max-width: 270px;
	margin: 30px 0 0;
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(1, 116, 216, 0.84);
	}
	&__wrapper{
		position: absolute;
		z-index: 2;
		max-width: calc(100% - 32px);
		bottom: 34px;
		left: 16px;
	}
	&__title{
		display: block;
		color: #fff;
		font-size: 24px;
		line-height: 30px;
	}
	&__button{
		display: inline-block;
		padding: 7px 16px;
		margin: 20px 0 0;
		color: $color-general;
		font-size: 16px;
		line-height: 21px;
		border-radius: 4px;
		border: 1px solid #fff;
		background-color: #fff;
		transition: .3s;
		&:hover{
			background-color: transparent;
			color: #fff;
		}
	}
}